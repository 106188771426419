.tracking-page {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: white;
}

.brand-preview-container {
  height: 750px;
  transform: scale(0.8);
  width: 102%; /* Fix for horizontal scrollbar appearing */
  transform-origin: top center;
}

.brand-preview {
  width: 100%;
  border-width: 0;
}

.tracking-info {
  justify-content: center;
  text-align: center;
}

.tracking-number {
  font-weight: bold;
}

.tracking-history-card .v-card-body {
  padding-bottom: 1remя;
}

.tracking-history {
  padding-left: 0;
}

.tracking-history-item {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.tracking-history-time {
  flex: 70px 0 0;
}

.tracking-history-name {
  font-weight: bold;
}
