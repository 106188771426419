.v-input {
  flex: 1;
  width: 100%;
}

.v-text {
  border: 0;
  outline: 0;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 700;
  border-radius: 4px 4px 0 0;
  margin-bottom: 1.8rem;
  border-bottom: 1px solid #e2e2e2;
  background: #fefefe;
  transition: var(--transition);
  color: black;
}

input.v-text {
  line-height: 4rem;
}

textarea.v-text {
  font-family: 'Arial', 'sans-serif';
  font-weight: normal;
}

.v-text:focus {
  background: #f7f7f7;
  border-bottom: 1px solid #aaaaaa;
}

.v-text.v-api-key-value {
  margin-bottom: 0.9rem;
  border: none;
  line-height: 3rem;
}

.v-text.v-email-override {
  font-size: 1.5rem;
  line-height: 3rem;
  margin-bottom: 0;
  margin-right: 0.5rem;
  border: none;
  padding-left: 0.5rem;
  font-weight: 500;
}

.v-text-label {
  display: block;
  color: #999999;
  font-size: 1.15rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 0.5rem;
  padding-bottom: 0.25rem;
}

.v-input-row {
  width: 100%;
  justify-content: flex-start;
}

.v-password {
  /*padding-right: 4rem;*/
}

.v-passowrd-icon {
  color: #999999;
  margin-top: -1rem;
  margin-left: -3rem;
  padding: 0.25rem;
  transition: var(--transition);
  cursor: pointer;
  top: 7px;
  position: relative;
}

.v-passowrd-icon:hover {
  color: #000000;
}

.v-text-black,
.v-text-black:focus {
  color: #ffffff;
  background: #000000;
  box-shadow: inset 0 0 0 50px #000;
}

.v-text-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
