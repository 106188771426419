.v-sidebar {
  display: flex;
  flex-direction: column;
  width: var(--sidebar-width);
  height: 100vh;
  color: #888888;
  background: #000000;
  flex-shrink: 0;
  overflow: hidden;
}

.v-sidebar.v-testmode {
  background: #00679c;
  color: #ffffff;
}

.v-sidebar-menu {
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
}

.v-sidebar .v-selected {
  color: #ffffff;
}

.v-sidebar-item {
  padding: 0 var(--sidebar-item-padding);
  line-height: var(--sidebar-items-height);
  cursor: pointer;
  transition: color 0.25s ease;
  display: block;
  color: #888888;
}

.v-testmode .v-sidebar-item {
  color: #ffffff;
}

.v-sidebar-items:hover .v-sidebar-items-label {
  color: #ffffff;
}

.v-sidebar-item:hover {
  color: #ffffff;
  background: #111111;
  /* font-weight: 600; */
}

.v-sidebar-list .v-sidebar-item {
  padding-left: calc(var(--sidebar-icon-spacing) + 2 * var(--sidebar-item-padding));
}

.v-sidebar-list {
  height: 0;
  overflow: hidden;
  transition: var(--transition);
  border-bottom: var(--separator);
}

.v-sidebar-items-label {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  padding-left: var(--sidebar-item-padding);
  padding-right: calc((0.75 + 0.25) * var(--sidebar-item-padding));
  line-height: var(--sidebar-items-height);
  transition: var(--transition);
  align-items: center;
  cursor: pointer;
}

.v-sidebar-items-label:hover {
  color: #ffffff;
  background: #111111;
  /* font-weight: 600; */
}

.v-sidebar-label-title {
  display: flex;
  align-items: center;
}

.v-item-icon {
  display: flex;
  align-items: center;
  margin-right: var(--sidebar-icon-spacing);
}

.v-sidebar-label-title .v-item-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.v-sidebar-items-label .v-arrow-icon {
  transition: var(--transition);
  transform: rotate(0deg);
}

.v-sidebar-items-label.v-open .v-arrow-icon {
  transform: rotate(90deg);
}

.v-sidebar-header {
  color: #ffffff;
  font-size: 2.4rem;
  line-height: 8rem;
  font-weight: bold;
  border-bottom: var(--separator);
  padding-left: var(--sidebar-item-padding);
  display: flex;
  align-items: center;
  border-bottom: 1px solid #333333;
}

.v-testmode .v-sidebar-header {
  border-bottom: 1px solid #2475a6;
}

.v-sidebar-header > a {
  color: #ffffff;
}

.v-sidebar-menu-icon {
  margin-top: -2px;
  transition: var(--transition);
  color: #eeeeee;
  cursor: pointer;
}

.v-sidebar-menu-icon:hover {
  color: #ffffff;
}

.v-footer {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  font-size: 1.3rem;
  border-top: 1px solid #0a0a0a;
}

.v-testmode .v-footer {
  border-top: 1px solid #2476a6;
}

.v-footer-links {
  padding: 2rem 0.25rem;
}

.v-footer-terms,
.v-footer-privacy {
  flex: 1;
  color: #555555;
  white-space: nowrap;
  border-bottom: 1px solid #222222;
}

.v-testmode .v-footer-terms,
.v-testmode .v-footer-privacy {
  color: #ffffff;
  border-bottom: 1px solid #2476a6;
}

.v-footer-terms:hover,
.v-footer-privacy:hover {
  border-bottom: 1px solid #555555;
}

.v-footer-terms {
  margin-right: 2.25rem;
}

.v-footer-copyright {
  color: #555555;
  white-space: nowrap;
}

.v-testmode .v-footer-copyright {
  color: #ffffff;
}
