.v-table-buy-labels .v-table-td.rates {
  overflow: visible;
}

.v-table-buy-labels .shipment-with-rates .v-table-td.rates .v-ellipsed {
  overflow: visible;
}

.v-table-buy-labels .v-select {
  height: 1rem;
  margin-bottom: 1rem;
}

.v-table-buy-labels .v-select-caption {
  padding-top: 0;
  padding-bottom: 0;
}

.v-table-buy-labels .v-select .v-arrow-icon {
  margin-left: 10px;
}

.v-table-buy-labels .date {
  max-width: 15rem;
}

.v-table-buy-labels .order-id {
  max-width: 10rem;
}

.v-table-buy-labels .rates {
  max-width: 30rem;
}

.v-table-buy-labels .actions {
  max-width: 10rem;
}

.v-table-buy-labels .shipment-loading {
  opacity: 0.5;
}

.v-table-buy-labels .shipment-purchased {
  opacity: 0.5;
}
