.v-awaiting-shipments {
  padding: 3rem 4rem;
}

.v-search-box:focus,
.v-search-box {
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  padding-left: 1rem;
  padding-right: 3rem;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.2;
  margin-bottom: 0;
  width: 100%;
}
.v-search-box::placeholder {
  color: #ababab;
}

.v-search-box:focus {
  background: #ffffff;
}

.v-clear-search-box-icon {
  width: 3rem;
  box-sizing: border-box;
  padding: 0.9rem;
  margin-left: -3.25rem;
  color: #777777;
  z-index: 0;
  transition: all 0.25s ease;
}

.v-clear-search-box-icon:hover {
  color: #000000;
}

.v-table-data-row {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  width: 100%;
}

.v-table-data-row-label {
  font-size: 1.4rem;
  display: inline-block;
  width: 18rem;
  color: #666666;
}

.v-table-data-row-info {
  font-size: 1.4rem;
  font-weight: 500;
  background: #fcfcfc;
  padding: 0.5rem 1rem;
  display: inline-block;
  border: 1px solid #f7f7f7;
  border-radius: 4px;
  word-break: break-all;
  max-width: calc(100% - 19rem);
}
