.v-subscription {
}

.v-subscription-card {
  padding: 2rem 2rem;
}

.v-subscription-title {
  font-size: 3.2rem;
  font-weight: 600;
  padding-bottom: 1.2rem;
}

.v-subscription-subtitle {
  color: #777777;
}
