.v-stripe-holder {
  padding: 1rem;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  background: #f2f2f2;
}

.v-stripe-comfort-message {
  margin: 1.5rem 0;
}

.v-stripe-highlight {
  display: inline-block;
  padding: 0 0.5rem;
  background: #6772e5;
  border-radius: 4px;
  color: #ffffff;
}
