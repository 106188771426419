.v-card {
  box-shadow: 0 0 0 2px #e2e2e2;
  border-radius: 4px;
  background: #ffffff;
  width: 100%;
  margin-bottom: 3rem;
}

.v-card-title {
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0 2rem;
  color: #999999;
  background: #e2e2e2;
  justify-content: flex-start;
  text-transform: uppercase;
  line-height: 3.5;
}

.v-card-body {
  /* display: flex;
    flex-direction: column; */
  flex: 1;
  padding: 2rem;
}

.v-dark-card {
  background: #000000;
  box-shadow: 0 0 0 1px #e2e2e2;
}

.v-dark-card::placeholder {
  color: #444444;
  opacity: 1;
}

.v-dark-card:-ms-input-placeholder {
  color: #444444;
}

.v-dark-card::-ms-input-placeholder {
  color: #444444;
}

.v-dark-card .v-passowrd-icon:hover {
  color: #ffffff !important;
}
