.v-toggle {
  display: block;
  border-radius: 10rem;
  width: 4.5rem;
  height: 2.4rem;
  position: relative;
  background: rgb(0, 0, 0);
  transition: all 0.25s ease;
  cursor: pointer;
}

.v-toggle.v-toggle-off {
  background: #999999;
}

.v-toggle-circle {
  display: block;
  border-radius: 10rem;
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  background: white;
  margin: 0.3rem;
  margin-left: 2.4rem;
  transition: all 0.25s ease;
}

.v-toggle.v-toggle-off .v-toggle-circle {
  margin-left: 0.3rem;
}

/* .v-toggle.toggle-off.disabled, */
.v-toggle.v-disabled {
  background: #bbbbbb;
  box-shadow: 0 0 1px 1px #999999;
}
