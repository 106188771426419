.manifest-form {
  align-items: center;
}

.manifest-form input {
  margin-bottom: 0;
  padding-left: 1rem;
}

.manifest-form .v-button {
  margin-left: 1rem;
}

.manifest-subtitle {
  margin-left: 1rem;
  color: #999;
}
