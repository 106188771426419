.v-table {
  display: block;
}

.v-table-table {
  font-size: 1.4rem;
  font-weight: 600;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

.v-table-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 0 1px #dddddd;
  border-radius: 2px;
  overflow-x: hidden;
  overflow-y: auto;
}

.v-table-auto-height > .v-table-content {
  overflow: unset;
}

.v-table-tr {
  background: #ffffff;
  display: flex;
  border-bottom: var(--separator);
  cursor: default;
  border: 1px solid transparent;
}

.v-table-tr-disabled .v-table-td:first-child {
  opacity: 0.5;
}

.v-table-th .v-checkbox,
.v-table-td .v-checkbox {
  padding: 0 0;
}

.v-table-td.v-no-pad {
  display: block;
  padding: 0;
}

.v-table-th,
.v-table-td {
  flex: 1;
  display: flex;
  align-items: center;
  border: var(--separator);
  padding: 0.75rem 1rem;
  overflow: hidden;
  height: 3.6rem;
  position: relative;
}

.v-table-tr:nth-child(odd) {
  background-color: #fcfcfc;
}

.v-table-tr:hover {
  background: #f7f7f7;
  border: 1px solid #dddddd;
}

.v-table-th {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0 1rem;
  color: #888888;
  background: #ebebeb;
  justify-content: flex-start;
  text-transform: uppercase;
}

.v-table-tr-empty {
  height: 15rem;
  color: #bbbbbb;
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-icon-status {
  white-space: normal !important;
  font-size: 1.2rem !important;
  display: flex;
  text-align: center;
}
