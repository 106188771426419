.v-pagination {
  padding: 2rem;
}

.v-pagination-li {
  outline: none;
}

.v-pagination-ul {
  display: inline-flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.v-pagination-ul a,
.v-pagination-ul a:focus {
  font-size: 1.3rem;
  outline: none;
}

.v-pagination-ul a {
  color: #555555;
  margin: 0.25rem;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  transition: var(--transition);
  display: inline-block;
  border-radius: 4px;
}

.v-pagination-page a:hover {
  color: #333333;
  background: #dddddd;
}

.v-pagination-page.selected a {
  color: #ffffff;
  background: #000000;
}

.v-pagination-ul .next {
  padding: 0 1rem;
  margin: 0 1rem;
  border-left: 1px dotted #999999;
}

.v-pagination-ul .previous {
  padding: 0 1rem;
  margin: 0 1rem;
  border-right: 1px dotted #999999;
}

.v-pagination-ul .next a:hover,
.v-pagination-ul .previous a:hover {
  color: #000000;
}

.v-pagination-ul .disabled a:hover,
.v-pagination-ul .disabled a {
  color: #cccccc;
  cursor: default;
}
