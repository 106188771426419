.v-grids {
  display: flex;
  width: 100%;
  flex: 1;
}

.v-grid-column {
  flex-direction: column;
}

.v-grid-1 {
  display: flex;
  flex: 1;
}

.v-grid-2 {
  display: flex;
  flex: 2;
}

.v-grid-3 {
  display: flex;
  flex: 3;
}

.v-grid-4 {
  display: flex;
  flex: 4;
}

.v-grid-5 {
  display: flex;
  flex: 5;
}

.v-grid-6 {
  display: flex;
  flex: 6;
}

.v-no-grid {
  display: unset;
  flex: 1;
}
