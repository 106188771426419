.v-button {
  padding: 0 2.8rem;
  box-shadow: 0 0 0 2px #000;
  height: 3.2rem;
  border-radius: 2px;
  transition: var(--transition-fast);
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  color: #ffffff;
  background: #000000;
  display: inline-flex;
}

.v-button:focus,
.v-button:hover {
  color: #000000;
  background: #ffffff;
  box-shadow: 0 0 0 2px #555;
}
.v-button:focus a,
.v-button:hover a {
  color: #ffffff;
}

.v-button-white {
  color: #555555;
  background: #ffffff;
  box-shadow: 0 0 0 1px #aaaaaa;
}
.v-button-white a {
  color: #555555;
}
.v-button-white:focus,
.v-button-white:hover {
  color: #ffffff;
  background: #000000;
  box-shadow: 0 0 0 2px #000000;
}

.v-button-round {
  border-radius: 10rem;
  padding: 0.5rem 2.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  box-shadow: 0 0 0 1px #cccccc;
}

.v-button-round:hover {
  box-shadow: 0 0 0 1px #000000;
}

.v-button-small {
  font-size: 1rem;
  font-weight: 600;
  padding: 0 1rem;
  height: 2.25rem;
  display: inline-flex;
  align-items: center;
  box-shadow: 0 0 0 1px #555;
  margin: 2px;
}

.v-button-small:hover {
  box-shadow: 0 0 0 1px #555;
}
.v-button-white.v-button-small:hover {
  box-shadow: 0 0 0 1px #000;
}

.v-icon-button {
  color: #555555;
  width: 2.8rem;
  height: 2.8rem;
  padding: 0.4rem;
  margin-right: 0.15rem;
  border-radius: 3px;
  transition: var(--transition);
  cursor: pointer;
  /*border: 1px solid transparent;*/
}

.v-icon-button.disabled {
  cursor: initial;
  opacity: 0.4;
}

.v-icon-button:hover {
  color: #000000;
  background: #ffffff;
  border: 1px solid #eeeeee;
}

.v-icon-disabled {
  color: #555555;
  background: #ffffff;
  opacity: 0.2;
}

.v-button-disabled,
.v-button-disabled:hover {
  color: #ffffff;
  background: #bbbbbb;
  box-shadow: 0 0 0 2px #bbbbbb;
  cursor: not-allowed;
  opacity: 0.5;
}

.v-button-round.v-button-disabled,
.v-button-round:disabled {
  color: #999999;
  background: #ffffff;
  box-shadow: 0 0 0 1px #aaaaaa;
}

.v-stripe-button {
  background: rgb(103, 114, 229);
  box-shadow: 0 0 3px #6772e5;
}

.v-title-button {
  /*margin-right: auto;*/
  margin-left: 2rem;
}

.v-title-button .v-button {
  padding: 0 1.8rem;
}

.v-username {
  margin-left: auto;
}

.rw-widget-input.rw-state-disabled, .rw-state-disabled .rw-widget-input {
  opacity: .5;
  background-color: #fff;
}
