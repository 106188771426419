:root {
  color-scheme: light dark; /* both supported */
}

.v-body {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

@media (prefers-color-scheme: dark) {
  .v-auto-darkmode .v-body,
  .v-auto-darkmode .v-modal {
    filter: invert(1);
  }
  .v-auto-darkmode .v-modal {
    box-shadow: 0 0 0 2px #aaa;
  }
}
.v-darkmode .v-body,
.v-darkmode .v-modal {
  filter: invert(1);
}
.v-darkmode .v-modal {
  box-shadow: 0 0 0 2px #aaa;
}
