.v-warehouse-address {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0 2rem 1rem 0;
}

.v-warehouse-actions {
  position: relative;
  margin-left: auto;
  white-space: nowrap;
}

.v-warehouse-action {
  margin: 0.25rem;
  padding: 0.75rem;
  box-sizing: content-box;
  cursor: pointer;
  border-radius: 4px;
  transition: var(--transition);
  background: #fcfcfc;
  display: inline-flex;
  border: 1px solid #eeeeee;
  color: #777777;
}

.v-open > .v-warehouse-action,
.v-warehouse-action:hover {
  background: #eeeeee;
  color: #000000;
}

.v-warehouse-action-items {
  display: flex;
  justify-content: flex-end;
  margin-top: 3.2rem;
  margin-right: -0.25rem;
  position: absolute;
  right: 0.5rem;
  top: 1.25rem;
}

.v-warehouse-action-container {
  position: absolute;
  right: 0.4rem;
  top: 0.4rem;
}

.v-remove-warehouse-address {
  padding: 2rem 0;
  font-size: 1.7rem;
  font-weight: 600;
}

.v-warehouse-empty {
  padding: 2rem 0;
  font-size: 1.5rem;
  text-align: center;
  color: #cccccc;
}
