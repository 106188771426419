.v-welcome-text {
  opacity: 0.15;
  color: #000000;
  font-size: 5rem;
  font-weight: 600;
  position: relative;
}

.v-welcome-logo {
  opacity: 0.15;
  font-size: 25rem;
  font-weight: 700;
  line-height: 0.75;
}

.v-welcome-username {
  position: absolute;
  opacity: 0.7;
  top: 0;
  right: 0;
  padding: 2rem;
  font-size: 1.5rem;
  font-weight: 500;
}
