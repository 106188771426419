.v-analytics-card {
  padding-top: 2rem;
  margin-right: 2rem;
  text-align: center;
}

.v-analytics-card .v-card-body {
  padding: 1rem;
}

.v-analytics-label {
  color: #777777;
  background: #ffffff;
  padding: 0 0.75rem;
}

.v-analytics-value {
  font-size: 3.5rem;
  font-weight: 600;
  margin: 1rem 0;
}

.v-analytics-lines {
  margin-bottom: -1rem;
  height: 0.4rem;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
