.v-credit-card-container {
  width: 100%;
  padding: 1.5rem;
  background: #ffffff;
  border-radius: 6px;
  position: relative;
  margin-bottom: 2rem;
}

.v-card-container-busy {
  background: #cccccc;
}

.v-card-removing-tag {
  background: #ee3b3b;
  color: #ffffff;
  padding: 0.4rem 1rem;
  border-radius: 4px;
  font-size: 1.2rem;
  margin: 0.25rem 0;
  display: inline-block;
}

.v-card-updating-tag {
  background: #36a736;
  color: #ffffff;
  padding: 0.4rem 1rem;
  border-radius: 4px;
  font-size: 1.2rem;
  margin: 0.25rem 0;
  display: inline-block;
}

.v-card-container-busy .v-credit-card-icon {
  opacity: 0.7;
}

.v-credit-card-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  color: #cccccc;
  width: 6rem;
  height: 6rem;
  margin-right: 2rem;
  padding: 0.5rem 1.5rem;
  border-radius: 8px;
  box-sizing: content-box;
  border: 1px solid #e3e3e3;
  background: #fefefe;
  flex-shrink: 0;
}

.v-credit-card-info {
  flex: 1;
}

.v-bank-account-issuer,
.v-credit-card-info-type {
  font-size: 1.8rem;
  font-weight: 500;
}

.v-bank-account-name {
  font-size: 1.4rem;
}

.v-bank-account-last4,
.v-credit-card-info-last4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
  color: #333333;
}

.v-credit-card-info-expire {
  font-size: 1.1rem;
  color: #777777;
}

.v-credit-card-info-expire > b {
  font-size: 1.3rem;
  font-weight: 500;
  color: #000000;
}

.v-credit-card-status {
  color: #ffffff;
  background: #000000;
  padding: 0.4rem 0.75rem;
  font-size: 1.1rem;
  border-radius: 3px;
  margin-right: 1rem;
}

.v-credit-card-menu {
  margin: 0.25rem;
  padding: 0.75rem;
  box-sizing: content-box;
  cursor: pointer;
  border-radius: 4px;
  color: #000000;
  transition: var(--transition);
}

.v-open > .v-credit-card-menu,
.v-credit-card-menu:hover {
  background: #eeeeee;
}

.v-credit-card-menu-items {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  margin-right: -0.25rem;
  position: absolute;
  right: 0.5rem;
  top: 1.25rem;
}

.v-credit-card-menu-container {
  position: absolute;
  right: 0.4rem;
  top: 0.4rem;
}

.v-credit-card-delete {
  padding: 0 0.75rem;
  margin-left: 0.75rem;
}

.v-credit-card-delete > .v-icon-14 {
  margin: 0;
  height: 1.6rem;
}

.v-credit-card-primary {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  color: #ffffff;
  background: #111111;
  border-radius: 4px;
}
