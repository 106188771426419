.v-login-page {
  height: 100vh;
  color: #ffffff;
  background: #000000;
  flex-direction: column;
}

.v-login-card {
  position: relative;
  padding: 3rem 5rem;
  border-radius: 1rem;
  width: 50rem;
  background: #000000;
  justify-content: flex-start;
  text-align: left;
  border-radius: 0.5rem;
}

.v-login-title {
  color: #ffffff;
  background: #000000;
  padding: 0 1rem;
  margin-bottom: -1rem;
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  z-index: 1;
}
