.v-menu {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
}

.v-menu-separator {
  height: 1px;
  background: #dddddd;
}

.v-menu-caption {
  justify-content: space-between;
  padding: 1rem 1.25rem;
  box-shadow: 0 0 1px 1px #999999;
  border-radius: 4px;
  margin-top: 0.75rem;
  transition: var(--transition);
}

.v-menu-caption > .v-arrow-icon {
  color: #999999;
  transform: rotate(90deg);
}

.v-menu-caption:hover,
.v-menu.v-open .v-menu-caption {
  color: #ffffff;
  background: #000000;
  box-shadow: 0 0 1px 1px #000000;
}

.v-menu.v-open > .v-menu-caption > .v-arrow-icon {
  color: inherit;
  transform: rotate(-90deg);
}

.v-menu-list-container {
  position: relative;
  margin-top: 2px;
}

.v-menu-list {
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(5 * 4rem);
  /* margin-top: calc(6rem); */
  box-shadow: 0 0 7px 0px #dddddd;
  border: 1px solid #dddddd;
  border-radius: 4px;
  z-index: 1;
  display: none;
}

.v-menu.v-open .v-menu-list {
  display: flex;
  flex-direction: column;
}

.v-menu-option {
  padding: 0 2rem;
  line-height: 4rem;
  display: flex;
  align-items: center;
  background: #ffffff;
  transition: var(--transition);
  cursor: pointer;
  white-space: nowrap;
  padding-right: 3rem;
}

.v-menu-option:hover {
  background: #f3f3f3;
}

.v-menu-option.v-menued {
  color: #000000;
  background: #dddddd;
}

.v-menu-disabled,
.v-menu-disabled:hover,
.v-menu-disabled > .v-arrow-icon {
  color: #ffffff;
  background: #cccccc;
  box-shadow: 0 0 0 2px #cccccc;
  cursor: not-allowed;
}

.v-menu-inside-table {
  overflow: visible;
}

.v-menu-right {
  right: 0;
}
