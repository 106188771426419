.v-hr {
  height: 1px;
  margin: 0.5rem 0;
  background: #e5e5e5;
  margin-top: 1rem;
  width: 100%;
}

.v-hr-2 {
  margin: 2rem 0;
}

.v-hr-2-5 {
  margin: 2.5rem 0;
}

.v-hr-3 {
  margin: 3rem 0;
}

.v-hr-4 {
  margin: 4rem 0;
}
