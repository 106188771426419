.v-style-guide {
  padding: 2rem;
}

.v-style-guide-sample {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid #eeeeee;
}

.v-style-guide-label {
  color: #777777;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  display: block;
}
