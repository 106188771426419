.v-refund-table {
  width: 100%;
  margin: 0.5rem 0;
}

.v-refund-table tr td {
  padding: 0.2rem 0.5rem;
}

.v-refund-table tr td:last-child {
  text-align: center;
}

.v-refund-table tr th:last-child {
  text-align: center;
}

.v-refund-table thead th {
  padding: 0.2rem 0.5rem;
  background: #f3f3f3;
  border-bottom: 1px solid #eeeeee;
}
