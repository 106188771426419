.v-dispute-expected input {
  margin-bottom: 0;
  line-height: 3rem;
  padding: 0.5rem;
}

.v-dispute-expected input:focus,
.v-dispute-details textarea:focus {
  border-bottom: 1px solid #e2e2e2;
  background: #fefefe;
}

.v-dispute-expected {
  margin-bottom: 1.8rem;
}

.v-dispute-details textarea {
  font-family: 'Arial', 'sans-serif';
  line-height: 2rem;
  padding: 0.5rem;
  resize: none;
}

.v-dispute-details label {
  width: 10rem;
}

.v-dispute-modal p:first-child,
.v-dispute-modal h2:first-child {
  margin-top: 0;
}

.v-dispute-value {
  margin-left: 0.5rem;
}

.v-dispute-card {
  margin-bottom: 2rem;
}

.v-dispute-form {
  margin-bottom: 0;
}
