.v-table-invoices .overdue .status,
.v-table-invoices .overdue .due {
  color: red;
  opacity: 0.7;
}

.v-table-invoices .paid span {
  opacity: 0.5;
}

.v-table-invoices .paid .v-table-td:last-child span {
  opacity: 1;
}

.v-invoices-total {
  padding-top: 2rem;
  text-align: center;
  margin-bottom: 0;
}

.v-invoices-total .v-card-body {
  padding: 1rem;
}

.v-invoices-total-label {
  color: #777777;
  background: #ffffff;
  padding: 0 0.75rem;
}

.v-invoices-total-paid {
  color: green;
}

.v-invoices-total-overdue {
  color: red;
}

.v-invoices-total-amount {
  font-size: 3.5rem;
  font-weight: 600;
  margin: 1rem 0;
}

.v-invoices-total-lines {
  margin-bottom: -1rem;
  height: 0.4rem;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.v-invoices-download {
  color: black;
  border: none;
  transition: none;
  margin-top: 0.5rem;
}

.v-icon-button:hover {
  background: none;
  border: none;
}

.v-pay-invoice-modal .v-input .v-text-label-left {
  margin-bottom: 1.8rem;
}

.v-pay-invoice-modal .v-text {
  line-height: 3rem;
  padding-left: 0.5rem;
}

.v-pay-invoice-modal .v-text:focus {
  border-bottom: 1px solid #e2e2e2;
  background: #fefefe;
}
