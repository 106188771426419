@import url('https://fonts.googleapis.com/css?family=Open+Sans');

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/AvenirNext-Bold-01.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/AvenirNext-DemiBold-03.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/AvenirNext-Medium-06.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('./fonts/AvenirNext-Regular-08.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

html,
body {
  font-family: 'Avenir Next', 'Open Sans', 'Arial', 'sans-serif';
  font-size: 10px;
  font-weight: 500;
  color: #000000;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 1.4rem;
  font-weight: 500;
}

* {
  box-sizing: border-box;
}

hr {
  margin: 3rem 0;
  padding: 0;
}

button,
button:focus {
  outline: 0;
  border: 0;
  background: white;
}

button {
  padding: 0;
}

a {
  color: #666666;
  text-decoration: none;
}
a:hover,
a:focus {
  text-decoration: underline;
}

::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}

::-webkit-scrollbar-thumb {
  background: #cccfdc;
  border-radius: 10rem;
  border: 2px solid #f7f8fa;
}

::-webkit-scrollbar-track {
  background: #f7f8fa;
  border-radius: 0;
}

::placeholder {
  color: #dddddd;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #dddddd;
}

::-ms-input-placeholder {
  color: #dddddd;
}

.v-dark-scroller::-webkit-scrollbar-thumb {
  background: #333333;
  border-radius: 10rem;
  border: 1px solid #000000;
}

.v-dark-scroller::-webkit-scrollbar-track {
  background: #000000;
  border-radius: 0;
}

.v-testmode .v-dark-scroller::-webkit-scrollbar-thumb {
  background: #eeeeee;
  border: 1px solid #000000;
}

.v-testmode .v-dark-scroller::-webkit-scrollbar-track {
  background: #00679c;
  border-radius: 0;
}

/*
:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus {
    background: #ffffff;
    -webkit-text-fill-color: #000000 !important;
    -webkit-box-shadow: inset 0 0 0 50px #ffffff;
}
*/

.vanlo-app {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.v-icon-10 {
  height: 1rem;
}

.v-icon-12 {
  height: 1.2rem;
}

.v-icon-14 {
  height: 1.4rem;
}

.v-icon-16 {
  height: 1.6rem;
}

.v-icon-18 {
  height: 1.8rem;
}

.v-icon-20 {
  height: 2rem;
}

.v-icon-22 {
  height: 2.2rem;
}

.v-icon-24 {
  height: 2.4rem;
}

.v-icon-copy-hash {
  height: 1.7rem;
  opacity: 0.4;
  transition: all 0.25s ease;
  cursor: pointer;
}

.v-icon-copy-hash:hover {
  opacity: 1;
}
