.v-current-balance {
  flex: 1;
  padding: 2rem 0;
  text-align: center;
}

.v-current-balance-label {
  font-size: 1.4rem;
  color: #777777;
  background: #ffffff;
  padding: 0 0.75rem;
}

.v-current-balance-amount {
  font-size: 3.5rem;
  font-weight: 600;
  margin: 2rem 0;
  display: flex;
  justify-content: center;
}

.v-current-balance-amount-sign {
  font-size: 60%;
  padding: 0.4rem;
}

.v-current-balance .v-button {
  margin: 0 auto;
}

.v-current-balance-lines {
  margin-bottom: -1rem;
  height: 0.4rem;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
