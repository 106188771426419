.v-checkbox {
  padding: 0.75rem 0;
  margin-right: 0.75rem;
}

.v-checkbox-label {
  margin: 0 0.75rem;
}

.v-checkbox-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: #000000;
}

.v-checkbox-box {
  width: 1.8rem;
  height: 1.8rem;
  flex-shrink: 0;
  border-radius: 3px;
  background: #ffffff;
  display: inline-block;
  border: 1px solid #333333;
}

.v-checkbox-tick {
  opacity: 1;
  position: absolute;
  width: 1.2rem;
  height: 1.3rem;
  margin-left: 0.3rem;
}

.v-checked {
  opacity: 0;
}

.v-checkbox-disabled {
  color: #999999;
  cursor: not-allowed;
}

.v-checkbox-disabled .v-checkbox-box {
  border: 1px solid #999999;
}

.v-table-checkbox {
  max-width: 5rem;
}
