.v-delete-person svg {
  margin: 0;
}

.v-person-toggle {
  min-width: 7rem;
}

.v-permission-checkbox span {
  margin: auto;
}
