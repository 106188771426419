.v-calendar,
.react-calendar {
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 0 1px #dddddd;
  border: 0;
}

.react-calendar__month-view__days__day,
.react-calendar__month-view__days__day--weekend {
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0.75rem 0;
  border-radius: 1px;
  cursor: pointer;
}

.react-calendar__navigation {
  border-bottom: 1px solid #dddddd;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #999999;
}

.react-calendar__month-view__weekdays {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem 0;
  text-transform: uppercase;
}

.react-calendar__century-view__decades__decade,
.react-calendar__decade-view__years__year,
.react-calendar__year-view__months__month {
  padding: 1.75rem 0.75rem;
  border-radius: 1px;
  cursor: pointer;
}

.react-calendar__year-view__months__month abbr:hover {
  padding: 0.25rem;
}

.react-calendar__month-view__days__day:hover,
.react-calendar__year-view__months__month:hover {
  background: #e2e2e2;
}

.react-calendar__month-view {
  /* padding: 1rem 1rem; */
}

.react-calendar__month-view__weekdays__weekday {
  text-align: center;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.react-calendar__navigation__label,
.react-calendar__navigation__arrow {
  padding: 1rem 1.5rem;
  font-weight: 700;
  cursor: pointer;
  transition: var(--transition);
}

.react-calendar__navigation__label {
  padding-left: 0;
  padding-right: 0;
}

.react-calendar__navigation__arrow {
  font-size: 1.6rem;
}

.react-calendar__navigation__label:hover,
.react-calendar__navigation__arrow:hover {
  background: #e2e2e2;
}

.react-calendar__tile--active:focus,
.react-calendar__tile--active:hover,
.react-calendar__tile--active,
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd,
.react-calendar__tile--rangeBothEnds,
.react-calendar__tile--hasActive:hover {
  color: #ffffff;
  border-radius: 1px;
  background: #111111;
  box-shadow: inset 0 0 0 1px #000000;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #111111;
}

.v-range-date:hover {
  color: #ffffff;
  background: #111111;
}

.v-range-date {
  display: inline-block;
  padding: 0.4rem 2rem;
  border: 1px solid #222222;
  margin: 0.4rem;
  border-radius: 5rem;
  cursor: pointer;
  transition: all 0.25s ease;
  text-align: center;
  white-space: nowrap;
}

.v-calendar-container {
  display: flex;
  width: 50rem;
}

.v-calendar-dates {
  display: flex;
  flex-direction: column;
}

.v-calendar-table {
  padding-right: 2rem;
}

.v-calendar-ranges {
  display: flex;
  align-items: center;
}

.v-calendar-range {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  background: #f7f7f7;
  border-radius: 2px;
  border: none;
  outline: 0;
  font-size: 100%;
  width: 10rem;
}

.v-calendar-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
