.v-fullscreen {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 1.5rem;
}

.v-link {
  color: #000000;
  border-bottom: 1px solid #cccccc;
  text-decoration: none;
  transition: var(--transition);
}

.v-link:hover {
  opacity: 1;
  border-bottom: 1px solid #777777;
}

.v-link-no-line,
.v-link-no-line:hover {
  border-bottom: none;
}

.v-page {
  height: 100vh;
  overflow-x: hidden;
  padding: 3rem 4rem;
  background: #f9f9f9;
}

.v-title {
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 1.5rem;
  text-transform: capitalize;
}

.v-subtitle {
  font-weight: 500;
  font-size: 1.6rem;
  padding: 1rem 0;
  margin-top: 1.75rem;
  line-height: 1rem;
  color: #888888;
}

.v-center {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  text-align: center;
}

.v-center-column {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
}

.v-center-left, .v-center-left-right {
  display: flex;
  text-align: left;
  align-items: center;
  width: 100%;
}

.v-center-left {
  justify-content: flex-start !important;
}

.v-center-left-right {
  justify-content: space-between !important;
}

.v-center-right {
  display: flex;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.v-center-column.v-center-left {
  align-items: flex-start;
  justify-content: center;
}

.v-left {
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
}

.v-right {
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
}

.v-separator {
  border-bottom: var(--separator);
}

.v-ellipsed {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
}

.v-headline-label-text {
  font-size: 1.3rem;
  border-radius: 5rem;
  background: #f7f7f7;
  padding: 1rem 1.5rem;
  margin-left: -1.5rem;
}

.v-pointer {
  cursor: pointer;
}

.v-fixed {
  position: fixed !important;
}

.v-text-red {
  color: #ff3311;
}

.v-link {
  cursor: pointer;
}

.v-title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.v-hint {
  font-size: smaller;
  font-weight: lighter;
}
