pre {
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
}

.v-json-string {
  color: #008000;
}

.v-json-number {
  color: #0000ff;
}

.v-json-boolean {
  color: #b22222;
}

.v-json-null {
  color: #808080;
}

.v-json-key {
  color: #000000;
}
